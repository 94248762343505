html{
  background-attachment: fixed;
  background: linear-gradient(#FF7F01 0%, #FFBA00 100%);
  background-repeat: no-repeat !important;
  background-size: 100%;
  min-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: 0;
  padding: 0;
  box-sizing: border-box; 
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
